import Vue from "vue"

import type { User } from "@/types/user"

import GetterTypes from "@/store/getter-types"
import ActionTypes from "@/store/action-types"

const ClientUsersConsumer = Vue.extend({
  name: "ClientUsersConsumer",
  computed: {
    users(): User[] {
      return this.$store.state.allusers.users
    },
    onlineUsers(): User[] {
      return this.$store.getters[GetterTypes.ONLINE_CLIENT_USERS]
    },
    onlineUsersGroupedByGameID(): Record<string, User[]> {
      return this.$store.getters[
        GetterTypes.ONLINE_CLIENT_USERS_GROUPED_BY_GAME_ID
      ]
    }
  },
  created() {
    this.$store.dispatch(ActionTypes.SUBSCRIBE_TO_USERS)
  },
  beforeDestroy() {
    this.$store.dispatch(ActionTypes.UNSUBSCRIBE_FROM_USERS)
  }
})

export default ClientUsersConsumer
